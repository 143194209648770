import {Module, Mutation, VuexModule} from 'vuex-module-decorators'

@Module
export default class History extends VuexModule {

  backButtonTexts: string[] = [];

  public get backButtonText(): string {
    return this.backButtonTexts[this.backButtonTexts.length - 2] ?? '';
  }

  @Mutation
  addBackButtonText(text: string) {
    this.backButtonTexts.push(text);
  }

  @Mutation
  replaceBackButtonText(text: string) {
    if (this.backButtonTexts.length) {
      this.backButtonTexts.splice(this.backButtonTexts.length - 1, 1, text);
    }
    else {
      this.backButtonTexts.push(text);
    }
  }

  @Mutation
  onPopState() {
    this.backButtonTexts.splice(this.backButtonTexts.length - 2);
  }


}