


































































import {BrokerRecord} from '@/interfaces';
import { Component, Vue } from 'vue-property-decorator';
import TablePagination from '../TablePagination.vue'
import BrokerLogoLink from "@/components/BrokerLogoLink.vue";

@Component({
components: {
  BrokerLogoLink,
    TablePagination,
  },
})
export default class BrokersFooterComparison extends Vue {

  private get comparingBrokers(): BrokerRecord[] {
    return this.$store.getters.comparingBrokersSorted;
  }

  private get compareLinkTo(): string {

    const slugs: string[] = this.comparingBrokers.map((broker: BrokerRecord) => broker.broker.slug);

    return `/brokers/compare/${slugs.join('/')}`;
  }

  private toggleComparingBroker(broker: BrokerRecord) {
    this.$store.commit('toggleComparingBroker', broker);
  }

  private resetComparingBrokers(broker: BrokerRecord) {
    this.$store.commit('resetComparingBrokers', broker);
  }

}
