import { UrlListBrokerFieldData } from "@/interfaces";
import { FieldFormatter } from "./FieldFormatter";

enum AppStore {
  Google = 'google',
  Apple = 'apple',
}

export class MobileAppsFieldFormatter extends FieldFormatter {

  declare protected data: UrlListBrokerFieldData;

  getIsEmpty(): boolean {
    return this.data.value.length === 0;
  }

  getNumericSortValue(): number {
    return 0;
  }
  
  getAlphaSortValue(): string {
    return "";
  }

  getHtml(): string {

    const appStores: AppStore[] = [AppStore.Apple, AppStore.Google];

    const appStoreTitles: Record<AppStore, string> = {
      [AppStore.Apple]: 'Apple App Store',
      [AppStore.Google]: 'Google Play Store',
    }

    return this.data.value.map(url => {

      const appStore: AppStore | undefined = appStores.find(a => {
        return url.url.toLowerCase().indexOf(a) >= 0 || url.name.toLowerCase().indexOf(a) >= 0;
      });

      if (appStore) {
        return `
            <a href="${url.url}" target="_blank" rel="noopener" class="group inline-flex items-center space-x-1">
              <span class="group-hover:underline">${appStoreTitles[appStore]}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="text-gray-500" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
              </svg>
            </a>`;
      }

      return '';
    }).join(', ');
  }

  public getData(): UrlListBrokerFieldData {
    return super.getData();
  }
}

