import {
  BrokerCategorySlug,
  BrokerFieldSlug,
} from '@/enums';
import {BrokerField, BrokerRecord, BrokerCategory, Cryptocurrency} from '@/interfaces'
import {Module, Mutation, VuexModule} from 'vuex-module-decorators'

@Module
export default class Broker extends VuexModule {

  canShare = false;

  isLoading = true;

  cryptocurrencies: Cryptocurrency[] = [];

  brokers: BrokerRecord[] = [];

  categories: BrokerCategory[] = [];

  fields: BrokerField[] = [];

  public get brokerFields() {
    return (categorySlug: BrokerCategorySlug) => {
      const category: BrokerCategory | undefined = this.categories.find(category => category.slug === categorySlug);

      return this.fields.filter(field => field.brokerCategoryId === category?.id)
    };
  }

  public get brokerCategoriesById(): Record<number, BrokerCategory> {
    const brokerCategoriesById: Record<number, BrokerCategory> = {};

    this.categories.forEach(category => {
      brokerCategoriesById[category.id] = category;
    });

    return brokerCategoriesById;
  }

  public get brokerCategoriesBySlug(): Record<BrokerCategorySlug, BrokerCategory> {

    const empty = {
      id: 0,
      slug: '',
      name: '',
      description: '',
    };

    const brokerCategoriesBySlug: Record<BrokerCategorySlug, BrokerCategory> = {
      [BrokerCategorySlug.General]: this.categories.find(category => category.slug === BrokerCategorySlug.General) || empty,
      [BrokerCategorySlug.Save]: this.categories.find(category => category.slug === BrokerCategorySlug.Save) || empty,
      [BrokerCategorySlug.Trade]: this.categories.find(category => category.slug === BrokerCategorySlug.Trade) || empty,
      [BrokerCategorySlug.Misc]: this.categories.find(category => category.slug === BrokerCategorySlug.Misc) || empty,
    };

    return brokerCategoriesBySlug;
  }

  public get brokerFieldsById(): Record<number, BrokerField> {

    const fieldsById: Record<number, BrokerField> = {};

    this.fields.forEach(field => {
      fieldsById[field.id] = field;
    });

    return fieldsById;
  }

  public get fieldRecord(): Record<BrokerFieldSlug, BrokerField> {

    const obj: Record<BrokerFieldSlug, BrokerField> = {} as Record<BrokerFieldSlug, BrokerField>;

    this.fields.forEach(field => {
      obj[field.slug as BrokerFieldSlug] = field;
    })

    return obj;
  }

  @Mutation
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  setCanShare(canShare: boolean) {
    this.canShare = canShare;
  }

  @Mutation
  setFields(fields: BrokerField[]) {
    this.fields = fields;
  }

  @Mutation
  setBrokers(brokers: BrokerRecord[]) {
    this.brokers = brokers;
  }

  @Mutation
  setCryptocurrencies(cryptocurrencies: Cryptocurrency[]) {
    this.cryptocurrencies = cryptocurrencies;
  }

  @Mutation
  setCategories(categories: BrokerCategory[]) {
    this.categories = categories;
  }
}