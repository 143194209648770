













import { Component, Vue } from 'vue-property-decorator';

@Component
export default class BackButton extends Vue {

  private onClick() {
    history.back();
  }

  private get backButtonText(): string {
    return this.$store.getters.backButtonText;
  }

}
