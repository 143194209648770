import { NumberBrokerFieldData } from "@/interfaces";
import { FieldFormatter } from "./FieldFormatter";

export class IntegerFieldFormatter extends FieldFormatter {

  declare protected data: NumberBrokerFieldData;

  getIsEmpty(): boolean {
    return this.data.value !== null;
  }

  getNumericSortValue(): number {
    return this.data.value === null ? 0 : this.data.value;
  }
  
  getAlphaSortValue(): string {
    return "";
  }

  getHtml(): string {
    return this.data.value === null ? '<span class="text-gray-300">-</span>' : `${this.data.value}`;
  }

  public getData(): NumberBrokerFieldData {
    return super.getData();
  }
}