


















































































































































































































































import {
  BrokerCategory,
  BrokerRecord,
  BrokerTableFilter,
  Cryptocurrency, FieldData,
  Table, TableCellPosition
} from '@/interfaces';
import BackButton from '@/components/BackButton.vue';
import {Component, Vue, Watch} from 'vue-property-decorator';
import PaginatedTable from '../PaginatedTable.vue'
import TablePagination from '../TablePagination.vue'
import Modal from '../Modal.vue'
import {
  BrokerCategorySlug,
  BrokerFieldSlug,
  HeadquartersLocation,
  RegulationStatus,
  SupportedCryptocurrencies
} from '@/enums';
import Broker from "@/modules/broker";
import BrokerTableModule from "@/modules/brokerTable";
import BrokerTableRow from "@/components/BrokerTable/BrokerTableRow.vue";
import FieldDataModal from "@/components/BrokerTable/FieldDataModal.vue";

@Component({
components: {
  FieldDataModal,
  BrokerTableRow,
    PaginatedTable,
    TablePagination,
    Modal,
    BackButton
  },
})
export default class BrokerTable extends Vue {

  private isOpen = false;

  private isFieldDataModalOpen = false;

  /**
   * Row and cell index of the current field data modal
   * @private
   */
  private tableCellPosition: TableCellPosition = {
    row: 0,
    cell: 0,
  }

  private get RegulationStatus() {
    return RegulationStatus;
  }

  private get HeadquartersLocation() {
    return HeadquartersLocation;
  }

  private get BrokerCategorySlug() {
    return BrokerCategorySlug;
  }

  private get SupportedCryptocurrencies() {
    return SupportedCryptocurrencies;
  }

  private get isDefaultFilter(): boolean {
    return this.$store.getters['isDefaultFilter'];
  }

  private get categorySlug(): BrokerCategorySlug {
    return this.$store.state.brokerTable.categorySlug;
  }

  private set categorySlug(categorySlug: BrokerCategorySlug) {
    this.$store.commit('setCategorySlug', categorySlug);

    this.syncSort();
  }

  private get cryptocurrencies(): Cryptocurrency[] {
    return (this.$store.state.broker as Broker).cryptocurrencies.sort((a, b) => a.ticker.localeCompare(b.ticker));
  }

  private get categories(): BrokerCategory[] {
    const slugs: BrokerCategorySlug[] = [BrokerCategorySlug.Save, BrokerCategorySlug.Trade];
    return (this.$store.state.broker as Broker).categories.filter(category => slugs.includes(category.slug as BrokerCategorySlug));
  }

  private get supportedCryptocurrencies(): SupportedCryptocurrencies {
    return this.$store.state.brokerTable.filter.supportedCryptocurrencies;
  }

  private set supportedCryptocurrencies(supportedCryptocurrencies: SupportedCryptocurrencies) {
    this.$store.commit('setSupportedCryptocurrencies', supportedCryptocurrencies);
  }

  private get selectCryptocurrencies(): Cryptocurrency[] {
    return (this.$store.state.brokerTable as BrokerTableModule).filter.selectCryptocurrencies;
  }

  private set selectCryptocurrencies(cryptocurrencies: Cryptocurrency[]) {
    const filter: BrokerTableFilter = Object.assign<{}, BrokerTableFilter, BrokerTableFilter>({}, (this.$store.state.brokerTable as BrokerTableModule).filter, {
      selectCryptocurrencies: cryptocurrencies ?? [],
    } as BrokerTableFilter);
    this.$store.commit('setFilter', filter);
  }

  private get modalBrokerFieldSlug(): BrokerFieldSlug | undefined {
    return this.$store.getters.brokerTableFields[this.tableCellPosition.cell];
  }

  private get modalBroker(): BrokerRecord | undefined {
    return this.filteredAndSortedBrokers[this.tableCellPosition.row];
  }

  private get modalBrokerFieldData(): FieldData | undefined {
    return this.modalBroker && this.modalBrokerFieldSlug ? this.modalBroker.fields[this.modalBrokerFieldSlug] : undefined;
  }

  private get regulationStatus(): RegulationStatus {
    return this.$store.state.brokerTable.filter.regulationStatus;
  }

  private set regulationStatus(regulationStatus: RegulationStatus) {
    this.$store.commit('setRegulationStatus', regulationStatus);
  }

  private get headquartersLocation(): HeadquartersLocation {
    return this.$store.state.brokerTable.filter.headquartersLocation;
  }

  private set headquartersLocation(headquartersLocation: HeadquartersLocation) {
    this.$store.commit('setHeadquartersLocation', headquartersLocation);
  }

  private get filteredAndSortedBrokers(): BrokerRecord[] {
    return this.$store.getters.filteredAndSortedBrokers;
  }

  private get comparingBrokers(): BrokerRecord[] {
    return this.$store.state.brokerTable.comparingBrokers;
  }

  private get table(): Table {
    return this.$store.getters['table'];
  }

  @Watch('table')
  onPropertyChanged(): void {
    this.$nextTick(() => this.updateData());
  }

  private resetFilterToDefault(): void {
    this.$store.commit('resetFilterToDefault');
    this.isOpen = false;
  }

  private updateData() {
    this.$store.commit('setTable', this.table);
  }

  private onSort(i: number) {
    this.$store.commit('updateSort', this.$store.getters.brokerTableFields[i]);
    this.syncSort();
  }

  private showFieldDataModal(tableCellPosition: TableCellPosition) {
    this.tableCellPosition = tableCellPosition;
    this.isFieldDataModalOpen = true;
  }

  private syncSort(): void {
    const index = this.$store.getters.brokerTableFields.indexOf((this.$store.state.brokerTable as BrokerTableModule).sortField)

    this.$store.commit('setTableSortIndex', index >= 0 ? index : NaN);
    this.$store.commit('setTableSortOrder', (this.$store.state.brokerTable as BrokerTableModule).sortOrder);
  }

  public mounted() {
    this.updateData();
    this.syncSort();
  }
}
