



























































































import {PageRange, Swipe, Table} from '@/interfaces';
import {Component, Vue, Watch} from 'vue-property-decorator';
import {SortOrder} from "@/enums";
import {EventBus} from "@/event-bus";
import {SwipeDirection} from "@/enums";
import PaginatedTableModule from "@/modules/paginatedTable";

enum ArrowState {
  Invisible,
  PartiallyVisible,
  FullyVisible,
}

@Component
export default class PaginatedTable extends Vue {

  private isOpen = false;

  private arrowLeft: ArrowState = ArrowState.Invisible;

  private arrowRight: ArrowState = ArrowState.Invisible;

  private get SortOrder() {
    return SortOrder;
  }

  private get ArrowState() {
    return ArrowState;
  }

  private get sortOrder(): SortOrder {
    return (this.$store.state.paginatedTable as PaginatedTableModule).sortOrder;
  }

  private get sortIndex(): number {
    return (this.$store.state.paginatedTable as PaginatedTableModule).sortIndex;
  }

  private get pages(): PageRange[] {
    return (this.$store.state.paginatedTable as PaginatedTableModule).pages;
  }

  private set pages(pages: PageRange[]) {
    this.$store.commit('setPages', pages);
  }

  private get page(): number {
    return (this.$store.state.paginatedTable as PaginatedTableModule).page;
  }

  private set page(page: number) {
    this.$store.commit('setPage', page);
  }

  private get table(): Table {
    return (this.$store.state.paginatedTable as PaginatedTableModule).table;
  }

  private get range(): PageRange {
    return this.pages[Math.min(this.page, this.pages.length - 1)];
  }

  private get isNextPage(): boolean {
    return this.page + 1 < this.pages.length;
  }

  private get isPreviousPage(): boolean {
    return this.page > 0;
  }

  @Watch('table')
  onPropertyChanged(): void {
    this.$nextTick(() => this.onResize());
  }

  /**
   * Calculate pages
   */
  private calculatePages(): void {

    const pages: PageRange[] = [{
      from: 0,
      to: 0,
    }];

    const containerRect = (this.$el.parentElement as HTMLElement).getBoundingClientRect();
    const containerWidth = containerRect.width;

    let pageWidth = 0;

    this.$el.querySelectorAll('th').forEach((el, i) => {
      const cellRect = el.getBoundingClientRect();
      const cellWidth = Math.floor(cellRect.width);

      // If this cell fits in the current page
      if (pageWidth === 0 || ( pageWidth + cellWidth ) < containerWidth) {
        // Add to current page width
        pageWidth += cellWidth;

        // Adjust page to index
        pages[pages.length - 1].to = i;
      }
      // If this cell belongs on the next page
      else {
        // Add a new page
        pages.push({
          from: i,
          to: i,
        });
        
        // Reset page width
        pageWidth = cellWidth;
      }
    });

    this.pages = pages;
  }

  /**
   * On resize
   */
  private onResize(): void {
    this.pages = [{
      from: 0,
      to: this.table.headerRow.cells.length - 1,
    }];

    this.$nextTick(() => this.calculatePages());
  }

  /**
   * On VueJS mount
   */
  public mounted() {
    this.$nextTick(() => this.onResize());

    EventBus.$on('windowResize', () => this.onResize());

    EventBus.$on('documentKeyDown', (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowLeft':
          this.page = Math.max(0, this.page - 1);
          break;
        case 'ArrowRight':
          this.page = Math.min(this.pages.length - 1, this.page + 1);
          break;
      }
    });

    EventBus.$on('touchStart', (e: TouchEvent) => {
      if (e.changedTouches.length && e.changedTouches[0].target instanceof Node) {
        if (this.$refs.container instanceof HTMLElement) {
          if (this.$refs.container.contains(e.changedTouches[0].target)) {
            this.arrowLeft = ArrowState.PartiallyVisible;
            this.arrowRight = ArrowState.PartiallyVisible;
          }
        }
      }
    });

    EventBus.$on('touchEnd', () => {
      this.arrowLeft = ArrowState.Invisible;
      this.arrowRight = ArrowState.Invisible;
    });

    EventBus.$on('swipeEnd', () => {
      this.arrowLeft = ArrowState.PartiallyVisible;
      this.arrowRight = ArrowState.PartiallyVisible;
    });

    EventBus.$on('swipeStart', (swipe: Swipe) => {
      if (swipe.e.changedTouches.length && swipe.e.changedTouches[0].target instanceof Node) {
        if (this.$refs.container instanceof HTMLElement) {
          if (this.$refs.container.contains(swipe.e.changedTouches[0].target)) {
            switch (swipe.direction) {
              case SwipeDirection.Left:
                this.arrowLeft = ArrowState.Invisible;
                this.arrowRight = ArrowState.FullyVisible;
                swipe.e.preventDefault();
                break;
              case SwipeDirection.Right:
                this.arrowLeft = ArrowState.FullyVisible;
                this.arrowRight = ArrowState.Invisible;
                swipe.e.preventDefault();
                break;
            }
          }
        }
      }
    });

    EventBus.$on('swipe', (swipe: Swipe) => {
      if (swipe.e.changedTouches.length && swipe.e.changedTouches[0].target instanceof Node) {
        if (this.$refs.container instanceof HTMLElement) {
          if (this.$refs.container.contains(swipe.e.changedTouches[0].target)) {
            switch (swipe.direction) {
              case SwipeDirection.Left:
                this.page = Math.min(this.pages.length - 1, this.page + 1);
                break;
              case SwipeDirection.Right:
                this.page = Math.max(0, this.page - 1);
                break;
            }
          }
        }
      }
    });
  }
}
