import {BrokerRecord, TransferRangeBrokerFieldData} from '@/interfaces'
import {Module, Mutation, VuexModule} from 'vuex-module-decorators'
import store from '@/store';
import {BrokerFieldSlug} from "@/enums";

@Module
export default class IndividualBroker extends VuexModule {

  slug = '';

  moreInfo: Record<BrokerFieldSlug, boolean> = {
    [BrokerFieldSlug.Url]: false,
    [BrokerFieldSlug.Headquarters]: false,
    [BrokerFieldSlug.Founded]: false,
    [BrokerFieldSlug.Regulated]: false,
    [BrokerFieldSlug.Regulators]: false,

    // Deposit

    [BrokerFieldSlug.DepositAch]: false,
    [BrokerFieldSlug.DepositBtc]: false,
    [BrokerFieldSlug.DepositEth]: false,
    [BrokerFieldSlug.DepositLtc]: false,
    [BrokerFieldSlug.DepositUsdc]: false,

    // Withdrawal

    [BrokerFieldSlug.WithdrawalAch]: false,
    [BrokerFieldSlug.WithdrawalBtc]: false,
    [BrokerFieldSlug.WithdrawalEth]: false,
    [BrokerFieldSlug.WithdrawalLtc]: false,
    [BrokerFieldSlug.WithdrawalUsdc]: false,

    [BrokerFieldSlug.MobileApps]: false,
    [BrokerFieldSlug.Social]: false,

    [BrokerFieldSlug.BtcSaveApy]: false,
    [BrokerFieldSlug.EthSaveApy]: false,
    [BrokerFieldSlug.AdaSaveApy]: false,
    [BrokerFieldSlug.DotSaveApy]: false,
    [BrokerFieldSlug.UniSaveApy]: false,
    [BrokerFieldSlug.BchSaveApy]: false,
    [BrokerFieldSlug.LinkSaveApy]: false,
    [BrokerFieldSlug.LtcSaveApy]: false,
    [BrokerFieldSlug.BatSaveApy]: false,
    [BrokerFieldSlug.PaxgSaveApy]: false,
    [BrokerFieldSlug.UsdcSaveApy]: false,
    [BrokerFieldSlug.GusdSaveApy]: false,
    [BrokerFieldSlug.UsdtSaveApy]: false,

    [BrokerFieldSlug.Maker]: false,
    [BrokerFieldSlug.Maker100k]: false,
    [BrokerFieldSlug.Maker1m]: false,
    [BrokerFieldSlug.Taker]: false,
    [BrokerFieldSlug.Taker100k]: false,
    [BrokerFieldSlug.Taker1m]: false,
    [BrokerFieldSlug.BtcUsd]: false,
    [BrokerFieldSlug.EthUsd]: false,
    [BrokerFieldSlug.AdaUsd]: false,
    [BrokerFieldSlug.DotUsd]: false,
    [BrokerFieldSlug.UniUsd]: false,
    [BrokerFieldSlug.BchUsd]: false,
    [BrokerFieldSlug.LinkUsd]: false,
    [BrokerFieldSlug.LtcUsd]: false,
    [BrokerFieldSlug.BatUsd]: false,
    [BrokerFieldSlug.PaxgUsd]: false,
  };

  /**
   * Current individual broker
   */
  public get individualBroker(): BrokerRecord | undefined {
    return store.state.broker.brokers.find((b: BrokerRecord) => this.slug === b.broker.slug);
  }

  @Mutation
  setSlug(slug: string) {
    this.slug = slug;
  }

  @Mutation
  toggleIndividualBrokerMoreInfo(brokerFieldSlug: BrokerFieldSlug) {
    this.moreInfo[brokerFieldSlug] = !this.moreInfo[brokerFieldSlug];
  }

}