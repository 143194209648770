interface MetaInfo {
  title: string;
  description: string;
  url: string;
  keywords: string[];
}

const defaults: MetaInfo = {
  title: process.env.VUE_APP_TITLE,
  description: process.env.VUE_APP_DESCRIPTION,
  url: process.env.VUE_APP_URL,
  keywords: process.env.VUE_APP_KEYWORDS.split(',').map((keyword: string) => keyword.trim()),
}

const ogTitle: HTMLMetaElement | null = document.querySelector('meta[property="og:title"]');
const metaDescription: HTMLMetaElement | null = document.querySelector('meta[name="description"]');
const ogDescription: HTMLMetaElement | null = document.querySelector('meta[property="og:description"]');
const ogUrl: HTMLMetaElement | null = document.querySelector('meta[property="og:url"]');
const metaKeywords: HTMLMetaElement | null = document.querySelector('meta[name="keywords"]');

export default function(metaInfo: MetaInfo) {

  const info: MetaInfo = Object.assign<{}, MetaInfo, MetaInfo>({}, defaults, metaInfo);

  document.title = info.title;

  if (ogTitle) {
    ogTitle.content = info.title;
  }

  if (metaDescription) {
    metaDescription.content = info.description;
  }

  if (ogDescription) {
    ogDescription.content = info.description;
  }

  if (ogUrl) {
    let url = info.url;

    // Base URL without trailing "/"
    const base = process.env.VUE_APP_URL.charAt(process.env.VUE_APP_URL.length - 1) === '/' ? process.env.VUE_APP_URL.substring(0, process.env.VUE_APP_URL.length - 1) : process.env.VUE_APP_URL;

    if (url.indexOf('https://') !== 0) {
      url = `${base}${url}`;
    }

    ogUrl.content = url;
  }

  if (metaKeywords) {
    metaKeywords.content = info.keywords.join(', ');
  }
}
