import {NumberBrokerFieldData, TransferData, TransferRangeBrokerFieldData} from "@/interfaces";
import { FieldFormatter } from "./FieldFormatter";

export class TransferFieldFormatter extends FieldFormatter {

  declare protected data: TransferRangeBrokerFieldData;

  public static percentNumberFormat = new Intl.NumberFormat("en-US", {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  getIsEmpty(): boolean {
    return this.data.value.from.amount === null && this.data.value.from.percent === null;
  }

  getNumericSortValue(): number {

    const base = ( this.data.value.from.networkFee || this.data.value.to.networkFee ) ? Number.MIN_VALUE : 0;

    if (this.data.value.to.percent !== null) {
      return base + ( this.data.value.to.percent * 100 );
    }

    if (this.data.value.to.amount !== null) {
      return base + ( this.data.value.to.amount );
    }

    if (this.data.value.from.percent !== null) {
      return base + ( this.data.value.from.percent * 100 );
    }

    if (this.data.value.from.amount !== null) {
      return base + ( this.data.value.from.amount );
    }

    return base;
  }
  
  getAlphaSortValue(): string {
    return "";
  }

  getHtmlSegment(transferData: TransferData): string {

    if (transferData.percent === 0) {
      if (transferData.networkFee) {
        return 'network fee';
      }
      else {
        return 'free';
      }
    }

    if (transferData.percent !== null) {
      if (transferData.networkFee) {
        return `${TransferFieldFormatter.percentNumberFormat.format(transferData.percent)} + network fee`;
      }
      else {
        return TransferFieldFormatter.percentNumberFormat.format(transferData.percent);
      }
    }

    if (transferData.amount === 0) {
      if (transferData.networkFee) {
        return 'network fee';
      }
      else {
        return 'free';
      }
    }

    if (transferData.amount !== null) {
      if (transferData.networkFee) {
        return `${transferData.amount} + network fee`;
      }
      else {
        return `${transferData.amount} ${transferData.asset}`;
      }
    }

    return '';
  }

  getHtml(): string {

    const from = this.getHtmlSegment(this.data.value.from),
      to = this.getHtmlSegment(this.data.value.to);

    if (from && to) {
      // return from === to ? from : `${from}–${to}`;

      if (from !== to) {

        return `<span class="text-gray-300">${from}–</span>${to}`;

        return `<span class="flex flex-col">
            <span>${to}</span>
            <span class="text-gray-300">${from}</span>
        </span>`;
      }
      else {
        return from;
      }
    }

    if (from) {
      return from;
    }

    return '<span class="text-gray-300">-</span>';
  }

  public getData(): TransferRangeBrokerFieldData {
    return super.getData();
  }
}