export default class LocalStore {

  /**
   * Store item in local storage
   *
   * @param key
   * @param value
   */
  public static setItem(key: string, value: unknown) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Get item from local storage
   *
   * @param key
   */
  public static getItem<T>(key: string): T | undefined {
    const item = localStorage.getItem(key);1
    return typeof item === 'string' ? JSON.parse(item) : undefined;
  }
}