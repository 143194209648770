











import {Component, Prop, Vue} from 'vue-property-decorator';
import {FieldData} from "@/interfaces";

@Component
export default class BrokerFieldComment extends Vue {

  @Prop(Object) readonly brokerFieldData: FieldData | undefined;

}
