import { LocationBrokerFieldData } from "@/interfaces";
import { FieldFormatter } from "./FieldFormatter";

export class LocationFieldFormatter extends FieldFormatter {

  declare protected data: LocationBrokerFieldData;

  getIsEmpty(): boolean {
    return !this.data.value.countryName.toLowerCase();
  }

  getNumericSortValue(): number {
    return 0;
  }
  
  getAlphaSortValue(): string {
    return this.data.value.countryName.toLowerCase();
  }

  getHtml(): string {
    return this.data.value.countryName ? this.data.value.countryName : '<span class="text-gray-300">-</span>';
  }

  public getData(): LocationBrokerFieldData {
    return super.getData();
  }
}