<template>
  <div>
    <BrokerTable/>
    <BrokersFooterComparison/>
  </div>
</template>

<script>
import BrokerTable from '../components/BrokerTable/BrokerTable.vue'
import BrokersFooterComparison from '../components/BrokerTable/BrokersFooterComparison.vue'
import title from '@/title';
import store from '@/store';
import meta from '@/meta';

export default {
  name: 'broker-table',
  beforeRouteEnter: (to, from, next) => {
    store.commit('replaceBackButtonText', 'Back to Broker Table');

    meta({
      title: title('Broker Table'),
      description: `Compare various crypto brokers in an easy-to-read table. Compare maker & taker fees, spreads, and other details.`, // @TODO list all broker here
      url: to.path,
      keywords: ['crypto', 'broker', 'comparison', 'vs.', 'versus', 'maker', 'taker', 'spread', 'commission', 'exchange', 'broker'],
    });

    next();
  },
  components: {
    BrokerTable,
    BrokersFooterComparison
  }
}
</script>
