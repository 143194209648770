import {BrokerFieldSlug, ModifyComparisonState, SortOrder,} from '@/enums';
import {BrokerRecord} from '@/interfaces'
import {Module, Mutation, VuexModule} from 'vuex-module-decorators'
import store from '@/store';
import defaultSortOrder from "@/defaultSortOrder";

@Module
export default class SelectiveBrokerCompare extends VuexModule {

  slugs: string[] = [];

  sortField: BrokerFieldSlug | undefined = undefined;

  sortOrder: SortOrder = SortOrder.Asc;

  modifyComparisonState: ModifyComparisonState = ModifyComparisonState.List;

  moreInfo: Record<BrokerFieldSlug, boolean> = {
    [BrokerFieldSlug.Url]: false,
    [BrokerFieldSlug.Headquarters]: false,
    [BrokerFieldSlug.Founded]: false,
    [BrokerFieldSlug.Regulated]: false,
    [BrokerFieldSlug.Regulators]: false,

    // Deposit

    [BrokerFieldSlug.DepositAch]: false,
    [BrokerFieldSlug.DepositBtc]: false,
    [BrokerFieldSlug.DepositEth]: false,
    [BrokerFieldSlug.DepositLtc]: false,
    [BrokerFieldSlug.DepositUsdc]: false,

    // Withdrawal

    [BrokerFieldSlug.WithdrawalAch]: false,
    [BrokerFieldSlug.WithdrawalBtc]: false,
    [BrokerFieldSlug.WithdrawalEth]: false,
    [BrokerFieldSlug.WithdrawalLtc]: false,
    [BrokerFieldSlug.WithdrawalUsdc]: false,

    [BrokerFieldSlug.MobileApps]: false,
    [BrokerFieldSlug.Social]: false,

    [BrokerFieldSlug.BtcSaveApy]: false,
    [BrokerFieldSlug.EthSaveApy]: false,
    [BrokerFieldSlug.AdaSaveApy]: false,
    [BrokerFieldSlug.DotSaveApy]: false,
    [BrokerFieldSlug.UniSaveApy]: false,
    [BrokerFieldSlug.BchSaveApy]: false,
    [BrokerFieldSlug.LinkSaveApy]: false,
    [BrokerFieldSlug.LtcSaveApy]: false,
    [BrokerFieldSlug.BatSaveApy]: false,
    [BrokerFieldSlug.PaxgSaveApy]: false,
    [BrokerFieldSlug.UsdcSaveApy]: false,
    [BrokerFieldSlug.GusdSaveApy]: false,
    [BrokerFieldSlug.UsdtSaveApy]: false,

    [BrokerFieldSlug.Maker]: false,
    [BrokerFieldSlug.Maker100k]: false,
    [BrokerFieldSlug.Maker1m]: false,
    [BrokerFieldSlug.Taker]: false,
    [BrokerFieldSlug.Taker100k]: false,
    [BrokerFieldSlug.Taker1m]: false,
    [BrokerFieldSlug.BtcUsd]: false,
    [BrokerFieldSlug.EthUsd]: false,
    [BrokerFieldSlug.AdaUsd]: false,
    [BrokerFieldSlug.DotUsd]: false,
    [BrokerFieldSlug.UniUsd]: false,
    [BrokerFieldSlug.BchUsd]: false,
    [BrokerFieldSlug.LinkUsd]: false,
    [BrokerFieldSlug.LtcUsd]: false,
    [BrokerFieldSlug.BatUsd]: false,
    [BrokerFieldSlug.PaxgUsd]: false,
  };

  /**
   * Alphabetized array of selected brokers
   */
  public get alphabetizedSelectedBrokers(): BrokerRecord[] {
    return store.state.broker.brokers
      .filter((b: BrokerRecord) => this.slugs.includes(b.broker.slug))
      .sort((a: BrokerRecord, b: BrokerRecord) => {
        return a.broker.name.localeCompare(b.broker.name);
      });
  }

  /**
   * Array of all selected brokers
   */
  public get sortedSelectedBrokers(): BrokerRecord[] {
    const brokers = store.state.broker.brokers
      .filter((b: BrokerRecord) => this.slugs.includes(b.broker.slug))
      .sort((a: BrokerRecord, b: BrokerRecord) => {
        if (this.sortField) {
          if (a.fields[this.sortField].alphaSortValue !== '' || b.fields[this.sortField].alphaSortValue !== '') {
            return a.broker.name.localeCompare(b.broker.name);
          }
          else {
            return ( a.fields[this.sortField].numericSortValue ?? 0 ) - ( b.fields[this.sortField].numericSortValue ?? 0 );
          }
        }
        else {
          return a.broker.name.localeCompare(b.broker.name);
        }
      });

    if (this.sortOrder === SortOrder.Desc) {
      brokers.reverse();
    }

    return brokers;
  }

  /**
   * Title of all selected brokers
   *
   * Something like "A, B, and C" or "A and B".
   */
  public get selectedBrokersTitle(): string {
    if (!this.alphabetizedSelectedBrokers.length)
    {
      return '';
    }

    if (this.alphabetizedSelectedBrokers.length === 1)
    {
      return '';
    }

    else if (this.alphabetizedSelectedBrokers.length === 2)
    {
      return this.alphabetizedSelectedBrokers.map(broker => broker.broker.name).join(' vs. ');
    }

    return this.alphabetizedSelectedBrokers
      .slice(0, this.alphabetizedSelectedBrokers.length - 1)
      .map(broker => broker.broker.name)
      .join(', ') + ', and ' + this.alphabetizedSelectedBrokers[this.alphabetizedSelectedBrokers.length - 1].broker.name;
  }

  @Mutation
  toggleSelectiveBrokerCompareMoreInfo(brokerFieldSlug: BrokerFieldSlug) {
    this.moreInfo[brokerFieldSlug] = !this.moreInfo[brokerFieldSlug];
  }

  @Mutation
  setSelectiveBrokerCompareSlugs(slugs: string[]) {
    this.slugs = slugs;
  }

  @Mutation
  setModifyComparisonState(modifyComparisonState: ModifyComparisonState) {
    this.modifyComparisonState = modifyComparisonState;
  }

  @Mutation
  updateSelectiveBrokerCompareSort(sortField: BrokerFieldSlug) {
    const fieldDefaultSortOrder = defaultSortOrder[sortField],
      fieldAlternateSortOrder = fieldDefaultSortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;

    // If this is a different sort field
    if (this.sortField !== sortField) {
      this.sortField = sortField;
      this.sortOrder = fieldDefaultSortOrder;
    }
    // If this is the same sort field
    else {
      // If we are already on the alternate sort order
      if (this.sortOrder === fieldAlternateSortOrder) {
        // Reset to default sort field
        this.sortField = undefined;
        this.sortOrder = SortOrder.Asc;
      }
      else {
        this.sortOrder = fieldAlternateSortOrder;
      }
    }
  }

}