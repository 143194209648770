




















































import {BrokerField, BrokerRecord, FieldData} from '@/interfaces';
import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue'
import {BrokerFieldSlug} from "@/enums";
import BrokerFieldComment from "@/components/BrokerFieldComment.vue";
import BrokerFieldSource from "@/components/BrokerFieldSource.vue";
import BrokerFieldUpdated from "@/components/BrokerFieldUpdated.vue";

@Component({
  components: {BrokerFieldUpdated, BrokerFieldSource, Modal, BrokerFieldComment}
})
export default class FieldDataModal extends Vue {

  @Prop(Object) readonly broker: BrokerRecord[] | undefined;

  @Prop(Object) readonly brokerFieldData: FieldData | undefined;

  @Prop(String) readonly brokerFieldSlug: BrokerFieldSlug | undefined;

  @Prop(Boolean) readonly isOpen: boolean | undefined;

  private get brokerField(): BrokerField | undefined {
    return this.brokerFieldSlug ? this.$store.getters.fieldRecord[this.brokerFieldSlug as BrokerFieldSlug] : undefined;
  }
}
