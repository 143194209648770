export enum SupportedCryptocurrencies {
  Any, Select
}

export enum RegulationStatus {
  Regulated, Unregulated, Any
}

export enum HeadquartersLocation {
  UnitedStates, Europe, Anywhere
}

export enum FieldType {
  Url,
  String,
  Location,
  FormattedNumber,
  Number,
  UrlArray,
  MobileApps,
  SocialUrls,
}

export enum BrokerSlug {
  Blockfi = "blockfi",
  Celsius = "celsius",
  Gemini = "gemini",
}

export enum BrokerCategorySlug {
  General = "general",
  Save = "save",
  Trade = "trade",
  Misc = "misc",
}

export enum BrokerFieldSlug {
  Url = "url",
  Headquarters = "headquarters",
  Founded = "founded",
  Regulated = "regulated",
  Regulators = "regulators",

  // Deposit

  DepositAch = 'deposit-ach',
  DepositBtc = 'deposit-btc',
  DepositEth = 'deposit-eth',
  DepositLtc = 'deposit-ltc',
  DepositUsdc = 'deposit-usdc',

  // Withdrawal

  WithdrawalAch = 'withdrawal-ach',
  WithdrawalBtc = 'withdrawal-btc',
  WithdrawalEth = 'withdrawal-eth',
  WithdrawalLtc = 'withdrawal-ltc',
  WithdrawalUsdc = 'withdrawal-usdc',

  MobileApps = "mobile-apps",
  Social = "social",

  BtcSaveApy = "btc-save-apy",
  EthSaveApy = "eth-save-apy",
  AdaSaveApy = "ada-save-apy",
  DotSaveApy = "dot-save-apy",
  UniSaveApy = "uni-save-apy",
  BchSaveApy = "bch-save-apy",
  LinkSaveApy = "link-save-apy",
  LtcSaveApy = "ltc-save-apy",
  BatSaveApy = "bat-save-apy",
  PaxgSaveApy = "paxg-save-apy",
  UsdcSaveApy = "usdc-save-apy",
  GusdSaveApy = "gusd-save-apy",
  UsdtSaveApy = "usdt-save-apy",

  Maker = "maker",
  Maker100k = "maker-100k",
  Maker1m = "maker-1m",
  Taker = "taker",
  Taker100k = "taker-100k",
  Taker1m = "taker-1m",
  BtcUsd = "btc-usd",
  EthUsd = "eth-usd",
  AdaUsd = "ada-usd",
  DotUsd = "dot-usd",
  UniUsd = "uni-usd",
  BchUsd = "bch-usd",
  LinkUsd = "link-usd",
  LtcUsd = "ltc-usd",
  BatUsd = "bat-usd",
  PaxgUsd = "paxg-usd",
}

export enum SortOrder {
  Asc,
  Desc
}

export enum ContinentCode {
  Africa = "AF",
  Antarctica = "AN",
  Asia = "AS",
  Europe = "EU",
  NorthAmerica = "NA",
  Oceania = "OC",
  SouthAmerica = "SA",
}

export enum FieldFormat {
  Location,
  Number,
  Url,
  Range,
  UrlList,
  MobileAppList,
  SocialList,
  PercentRange,
  Percent,
}

export enum SwipeDirection {
  Up,
  Down,
  Left,
  Right
}

export enum HeaderOverlay {
  None,
  Search,
  Profile,
  Menu
}

export enum ModifyComparisonState {
  List,
  Edit,
}