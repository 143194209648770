































import {Component, Prop, Vue} from 'vue-property-decorator';
import { EventBus } from '@/event-bus';

@Component
export default class Modal extends Vue {

  @Prop(Boolean) readonly open: boolean | undefined;

  private onClose() {
    this.$emit('close');
  }

  public mounted() {
    EventBus.$on('documentKeyDown', (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === 'escape' && this.open) {
        this.$emit('close');
      }
    });
  }
  
}
