import { FieldData } from "@/interfaces";

export abstract class FieldFormatter {

  protected data: FieldData;

  abstract getNumericSortValue(): number;

  abstract getAlphaSortValue(): string;

  abstract getHtml(): string;

  abstract getIsEmpty(): boolean;

  constructor(data: FieldData) {
    this.data = data;
  }

  public getData(): FieldData {
    return Object.assign({}, this.data, {
      html: this.getHtml(),
      numericSortValue: this.getNumericSortValue(),
      alphaSortValue: this.getAlphaSortValue(),
      isEmpty: this.getIsEmpty(),
    });
  }
}

