import { NumberBrokerFieldData } from "@/interfaces";
import { FieldFormatter } from "./FieldFormatter";

export class PercentFieldFormatter extends FieldFormatter {

  declare protected data: NumberBrokerFieldData;

  public static numberFormat = new Intl.NumberFormat("en-US", {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  getIsEmpty(): boolean {
    return this.data.value === null;
  }

  getNumericSortValue(): number {
    return this.data.value === null ? 0 : ( this.data.value === 0 ? Number.MIN_VALUE : this.data.value );
  }
  
  getAlphaSortValue(): string {
    return "";
  }

  getHtml(): string {
    return this.data.value === null ? '<span class="text-gray-300">-</span>' : PercentFieldFormatter.numberFormat.format(this.data.value);
  }

  public getData(): NumberBrokerFieldData {
    return super.getData();
  }
}