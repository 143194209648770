

















import {Component, Prop, Vue} from 'vue-property-decorator';
import {FieldData} from "@/interfaces";

@Component
export default class BrokerFieldSource extends Vue {

  @Prop(Object) readonly brokerFieldData: FieldData | undefined;

  private get displayUrl(): string {
      return ( this.brokerFieldData?.sourceUrl ? ( new URL(this.brokerFieldData?.sourceUrl) ).host : '' )
        .replace('www.', '');
  }

}
