



























































import {Component, Vue} from 'vue-property-decorator';
import data from '@/data';
import {HeaderOverlay, SwipeDirection} from './enums';
import {EventBus} from '@/event-bus';
import {Coordinates, Swipe} from "@/interfaces";
import Broker from "@/modules/broker";
import Overlay from "@/components/HeaderOverlay/Overlay.vue";

@Component({
  components: {Overlay}
})
export default class App extends Vue {

  private $onResizeTimeout!: number;

  private $touchStartCoordinates!: {
    x: number;
    y: number;
  };

  private $touchEndCoordinates!: {
    x: number;
    y: number;
  };

  private showResponsiveHelper = false;

  private headerOverlay: HeaderOverlay = HeaderOverlay.None;

  private get HeaderOverlay() {
    return HeaderOverlay;
  }

  private get isLoading() {
    return (this.$store.state.broker as Broker).isLoading;
  }

  private onSearchClick() {
    this.headerOverlay = HeaderOverlay.Search;
    this.$nextTick(() => EventBus.$emit('focusOnSearch'));
  }

  private headerOverlayToggle(headerOverlay: HeaderOverlay) {
    if (this.headerOverlay === headerOverlay) {
      this.headerOverlay = HeaderOverlay.None;
    }
    else {
      this.headerOverlay = headerOverlay;
    }
  }

  mounted() {

    const threshold = Math.min(window.innerWidth / 5, 100);

    this.$onResizeTimeout = 0;

    this.$touchStartCoordinates = {
      x: NaN,
      y: NaN,
    };

    this.$touchEndCoordinates = {
      x: NaN,
      y: NaN,
    };

    // Fetch all data
    data.then(result => {
      this.$store.commit('setIsLoading', false);
      this.$store.commit('setBrokers', result.brokers);
      this.$store.commit('setCategories', result.brokerCategories);
      this.$store.commit('setFields', result.brokerFields);
      this.$store.commit('setCryptocurrencies', result.cryptocurrencies);
    });

    this.$store.commit('setCanShare', 'share' in window.navigator);

    document.addEventListener('keydown', e => EventBus.$emit('documentKeyDown', e));

    window.addEventListener('resize', () => {
      clearTimeout(this.$onResizeTimeout);
      this.$onResizeTimeout = setTimeout(() => EventBus.$emit('windowResize'), 250);
    });

    // @TODO this is called twice with hot reloads
    document.addEventListener('touchstart', (e: TouchEvent) => {
      if (e.touches.length) {
        this.$touchStartCoordinates.x = e.touches[0].screenX;
        this.$touchStartCoordinates.y = e.touches[0].screenY;

        EventBus.$emit('touchStart', e);
      }
    }, false);

    document.addEventListener('touchend', (e: TouchEvent) => {
      const swipe: Swipe = this.getSwipe(e, threshold);

      if (swipe.direction) {
        EventBus.$emit('swipe', swipe);
      }

      EventBus.$emit('touchEnd', e);

      this.$touchStartCoordinates.x = NaN;
      this.$touchStartCoordinates.y = NaN;

      this.$touchEndCoordinates.x = NaN;
      this.$touchEndCoordinates.y = NaN;
    }, false);

    document.addEventListener('touchmove', (e: TouchEvent) => {
      if (e.changedTouches.length) {
        this.$touchEndCoordinates.x = e.changedTouches[0].screenX;
        this.$touchEndCoordinates.y = e.changedTouches[0].screenY;

        const swipe: Swipe = this.getSwipe(e, threshold);

        if (swipe.direction) {
          EventBus.$emit('swipeStart', swipe);
        }
        else {
          EventBus.$emit('swipeEnd', e);
        }
      }
    }, {
      passive: false,
    });
  }

  /**
   * Get swipe from mouse event
   *
   * @param e
   * @param min
   * @private
   */
  private getSwipe(e: TouchEvent, min = 100): Swipe {
    const delta: Coordinates = {
      x: this.$touchEndCoordinates.x - this.$touchStartCoordinates.x,
      y: this.$touchEndCoordinates.y - this.$touchStartCoordinates.y,
    }

    const swipe: Swipe = {
      e: e,
      direction: undefined,
    }

    // Up / down
    if (Math.abs(delta.y) > Math.abs(delta.x)) {
      if (Math.abs(delta.y) >= min) {
        if (delta.y < 0) {
          swipe.direction = SwipeDirection.Up;
        }
        else {
          swipe.direction = SwipeDirection.Down;
        }
      }
    }
    // Left / right
    else {
      if (Math.abs(delta.x) >= min) {
        if (delta.x < 0) {
          swipe.direction = SwipeDirection.Left;
        }
        else {
          swipe.direction = SwipeDirection.Right;
        }
      }
    }

    return swipe;
  }
}
