import {BrokerField, BrokerRecord, Cryptocurrency} from "@/interfaces";
import axios from "axios";

export default class Api {

  private static _instance: Api;
  private endpoint: string;

  private constructor(endpoint: string) {
    this.endpoint = endpoint;
    Api._instance = this;
  }

  public static get instance(): Api {
    if (!Api._instance) {
      return new Api(process.env.VUE_APP_API);
    }

    return Api._instance;
  }

  public getBrokers(): Promise<BrokerRecord[]> {
    return axios.get(`${this.endpoint}/api/brokers`).then(response => {
      return response.data;
    });
  }

  public getCryptocurrencies(): Promise<Cryptocurrency[]> {
    return axios.get(`${this.endpoint}/api/cryptocurrencies`).then(response => {
      return response.data;
    });
  }

  public getBrokerCategories(): Promise<BrokerField[]> {
    return axios.get(`${this.endpoint}/api/broker-categories`).then(response => {
      return response.data;
    });
  }

  public getBrokerFields(): Promise<BrokerField[]> {
    return axios.get(`${this.endpoint}/api/broker-fields`).then(response => {
      return response.data;
    });
  }

}