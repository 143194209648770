import {BrokerFieldSlug, SortOrder} from "./enums";

const defaultSortOrder: Record<BrokerFieldSlug, SortOrder> = {
  // General
  [BrokerFieldSlug.Url]: SortOrder.Asc,
  [BrokerFieldSlug.Headquarters]: SortOrder.Asc,
  [BrokerFieldSlug.Founded]: SortOrder.Asc,
  [BrokerFieldSlug.Regulated]: SortOrder.Desc,
  [BrokerFieldSlug.Regulators]: SortOrder.Asc,

  // Deposit

  [BrokerFieldSlug.DepositAch]: SortOrder.Asc,
  [BrokerFieldSlug.DepositBtc]: SortOrder.Asc,
  [BrokerFieldSlug.DepositEth]: SortOrder.Asc,
  [BrokerFieldSlug.DepositLtc]: SortOrder.Asc,
  [BrokerFieldSlug.DepositUsdc]: SortOrder.Asc,

  // Withdrawal

  [BrokerFieldSlug.WithdrawalAch]: SortOrder.Asc,
  [BrokerFieldSlug.WithdrawalBtc]: SortOrder.Asc,
  [BrokerFieldSlug.WithdrawalEth]: SortOrder.Asc,
  [BrokerFieldSlug.WithdrawalLtc]: SortOrder.Asc,
  [BrokerFieldSlug.WithdrawalUsdc]: SortOrder.Asc,

  // Misc
  [BrokerFieldSlug.MobileApps]: SortOrder.Asc,
  [BrokerFieldSlug.Social]: SortOrder.Asc,

  // Non-stablecoins
  [BrokerFieldSlug.BtcSaveApy]: SortOrder.Desc,
  [BrokerFieldSlug.EthSaveApy]: SortOrder.Desc,
  [BrokerFieldSlug.AdaSaveApy]: SortOrder.Desc,
  [BrokerFieldSlug.DotSaveApy]: SortOrder.Desc,
  [BrokerFieldSlug.UniSaveApy]: SortOrder.Desc,
  [BrokerFieldSlug.BchSaveApy]: SortOrder.Desc,
  [BrokerFieldSlug.LinkSaveApy]: SortOrder.Desc,
  [BrokerFieldSlug.LtcSaveApy]: SortOrder.Desc,
  [BrokerFieldSlug.BatSaveApy]: SortOrder.Desc,
  [BrokerFieldSlug.PaxgSaveApy]: SortOrder.Desc,

  // Stablecoins
  [BrokerFieldSlug.UsdcSaveApy]: SortOrder.Desc,
  [BrokerFieldSlug.GusdSaveApy]: SortOrder.Desc,
  [BrokerFieldSlug.UsdtSaveApy]: SortOrder.Desc,

  [BrokerFieldSlug.Maker]: SortOrder.Asc,
  [BrokerFieldSlug.Maker100k]: SortOrder.Asc,
  [BrokerFieldSlug.Maker1m]: SortOrder.Asc,

  [BrokerFieldSlug.Taker]: SortOrder.Asc,
  [BrokerFieldSlug.Taker100k]: SortOrder.Asc,
  [BrokerFieldSlug.Taker1m]: SortOrder.Asc,

  [BrokerFieldSlug.BtcUsd]: SortOrder.Asc,
  [BrokerFieldSlug.EthUsd]: SortOrder.Asc,
  [BrokerFieldSlug.AdaUsd]: SortOrder.Asc,
  [BrokerFieldSlug.DotUsd]: SortOrder.Asc,
  [BrokerFieldSlug.UniUsd]: SortOrder.Asc,
  [BrokerFieldSlug.BchUsd]: SortOrder.Asc,
  [BrokerFieldSlug.LinkUsd]: SortOrder.Asc,
  [BrokerFieldSlug.LtcUsd]: SortOrder.Asc,
  [BrokerFieldSlug.BatUsd]: SortOrder.Asc,
  [BrokerFieldSlug.PaxgUsd]: SortOrder.Asc,

}

export default defaultSortOrder;