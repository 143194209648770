import { UrlBrokerFieldData } from "@/interfaces";
import { FieldFormatter } from "./FieldFormatter";

export class UrlFieldFormatter extends FieldFormatter {

  declare protected data: UrlBrokerFieldData;

  getIsEmpty(): boolean {
    return this.data.value.name.trim() === '' && this.data.value.url.trim() === '';
  }

  getNumericSortValue(): number {
    return 0;
  }
  
  getAlphaSortValue(): string {
    return this.data.value.name.toLowerCase() || (new URL(this.data.value.url)).host.toLowerCase().replace('www.', '');
  }

  getHtml(): string {
    const name = this.data.value.name || (new URL(this.data.value.url)).host.replace('www.', '');
    return `
        <a href="${this.data.value.url}" target="_blank" rel="noopener" class="group inline-flex items-center space-x-1">
          <span class="group-hover:underline">${name}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="text-gray-500" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
            <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
          </svg>
        </a>
        
`;
  }

  public getData(): UrlBrokerFieldData {
    return super.getData();
  }
}