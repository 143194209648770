











import {Component, Prop, Vue} from 'vue-property-decorator';
import {BrokerRecord} from "@/interfaces";

@Component
export default class BrokerLogoLink extends Vue {

  @Prop(Object) readonly broker: BrokerRecord | undefined;

}
