import { RangeBrokerFieldData } from "@/interfaces";
import { FieldFormatter } from "./FieldFormatter";

export class PercentRangeFieldFormatter extends FieldFormatter {

  declare protected data: RangeBrokerFieldData;

  public static numberFormat = new Intl.NumberFormat("en-US", {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  getIsEmpty(): boolean {
    return this.data.value.min === null;
  }

  getNumericSortValue(): number {
    return this.data.value.min === null ? 0 : this.data.value.min;
  }
  
  getAlphaSortValue(): string {
    return "";
  }

  getHtml(): string {
    // If there is no min or max value
    if (this.data.value.min === null && this.data.value.max === null) {
      return '<span class="text-gray-300">-</span>';
    }

    // If there is a min and max value
    if (this.data.value.min !== null && this.data.value.max !== null && ( this.data.value.min !== this.data.value.max ) ) {
      return `<span class="flex flex-col">
                <span>${PercentRangeFieldFormatter.numberFormat.format(this.data.value.min)}</span>
                <span class="text-gray-300">${PercentRangeFieldFormatter.numberFormat.format(this.data.value.max)}</span>
              </span>`;
    }

    // If there is only a min value
    return PercentRangeFieldFormatter.numberFormat.format(this.data.value.min ?? 0);
  }

  public getData(): RangeBrokerFieldData {
    return super.getData();
  }
}