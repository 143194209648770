import {BooleanBrokerFieldData} from "@/interfaces";
import { FieldFormatter } from "./FieldFormatter";

export class YesNoFieldFormatter extends FieldFormatter {

  declare protected data: BooleanBrokerFieldData;

  getIsEmpty(): boolean {
    return this.data.value === null;
  }

  getNumericSortValue(): number {
    return this.data.value ? 1 : 0;
  }
  
  getAlphaSortValue(): string {
    return "";
  }

  getHtml(): string {
    return this.data.value ? 'yes' : 'no';
  }

  public getData(): BooleanBrokerFieldData {
    return super.getData();
  }
}

