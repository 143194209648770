import {
  BrokerCategorySlug,
  BrokerFieldSlug,
  HeadquartersLocation,
  RegulationStatus,
  SortOrder,
  SupportedCryptocurrencies
} from '@/enums';
import {BrokerField, BrokerRecord, BrokerTableFilter, Table} from '@/interfaces'
import {Module, Mutation, VuexModule} from 'vuex-module-decorators'
import store from '@/store';
import defaultSortOrder from "@/defaultSortOrder";

@Module
export default class BrokerTable extends VuexModule {

  maxComparingBrokers = 4;

  comparingBrokers: BrokerRecord[] = [];
  
  categorySlug: BrokerCategorySlug = BrokerCategorySlug.Trade;

  sortField: BrokerFieldSlug | undefined = undefined;
  sortOrder: SortOrder = SortOrder.Asc;

  filter: BrokerTableFilter = {
    supportedCryptocurrencies: SupportedCryptocurrencies.Any,
    regulationStatus: RegulationStatus.Any,
    headquartersLocation: HeadquartersLocation.Anywhere,
    selectCryptocurrencies: [],
  };

  brokerTableCommonFields: BrokerFieldSlug[] = [
    BrokerFieldSlug.Headquarters,
    BrokerFieldSlug.Founded,
    BrokerFieldSlug.Regulated,
  ];

  public get selectCryptocurrencyFields(): BrokerField[] {

    const brokerFieldsById: Record<number, BrokerField> = store.getters.brokerFieldsById,
      brokerCategory = store.getters.brokerCategoriesBySlug[this.categorySlug],
      fields: BrokerField[] = [];

    this.filter.selectCryptocurrencies.forEach(cryptocurrency => {
      cryptocurrency.broker_field_ids.forEach(id => {
        const field = brokerFieldsById[id];
        if (field.brokerCategoryId === brokerCategory.id) {
          fields.push(brokerFieldsById[id]);
        }
      });
    });

    return fields;
  }

  public get brokerTableCustomFields(): BrokerFieldSlug[] {

    // If 'Supported cryptocurrencies' is 'Any'
    switch (this.categorySlug) {
      case BrokerCategorySlug.Save:

        // If 'Supported cryptocurrencies' is 'Select'
        if (this.filter.supportedCryptocurrencies === SupportedCryptocurrencies.Select) {
          return this.selectCryptocurrencyFields.map(field => field.slug) as BrokerFieldSlug[];
        }

        return [
          BrokerFieldSlug.BtcSaveApy,
          BrokerFieldSlug.EthSaveApy,
          BrokerFieldSlug.LtcSaveApy,
          BrokerFieldSlug.UsdcSaveApy,
          BrokerFieldSlug.GusdSaveApy,
          BrokerFieldSlug.UsdtSaveApy,
        ];
      default:

        // If 'Supported cryptocurrencies' is 'Select'
        if (this.filter.supportedCryptocurrencies === SupportedCryptocurrencies.Select) {
          return [
            BrokerFieldSlug.Maker,
            BrokerFieldSlug.Taker
          ].concat(this.selectCryptocurrencyFields.map(field => field.slug) as BrokerFieldSlug[]);
        }

        return [
          BrokerFieldSlug.Maker,
          BrokerFieldSlug.Taker,
          BrokerFieldSlug.BtcUsd,
          BrokerFieldSlug.EthUsd,
          BrokerFieldSlug.AdaUsd,
          BrokerFieldSlug.DotUsd,
          BrokerFieldSlug.UniUsd,
          BrokerFieldSlug.BchUsd,
        ];
    }
  }

  public get isMaxComparingBrokers(): boolean {
    return this.comparingBrokers.length >= this.maxComparingBrokers;
  }

  public get brokerTableFields(): BrokerFieldSlug[] {
    return this.brokerTableCommonFields.concat(this.brokerTableCustomFields);
  }

  public get brokerTableSortFieldIndex(): number {
    return this.sortField ? this.brokerTableFields.indexOf(this.sortField) : -1;
  }

  get table(): Table {
    return {
      headerRow: {
        cells: this.brokerTableFields.map(field => {
          return {
            text: store.getters.fieldRecord[field]?.name,
            sub: store.getters.fieldRecord[field]?.description,
          }
        }),
      },
      bodyRows: this.filteredAndSortedBrokers.map(broker => {
        return {
          cells: this.brokerTableFields.map(field => {
            return {
              text: broker.fields[field]?.html ?? '',
              source: '',
              comment: broker.fields[field]?.comment ?? '',
            };
          })
        }
      }),
    };
  }

  /**
   * Alphabetically sorted array of comparing brokers
   */
  public get comparingBrokersSorted(): BrokerRecord[] {

    const comparingBrokers = JSON.parse(JSON.stringify(this.comparingBrokers));

    comparingBrokers.sort((a: BrokerRecord, b: BrokerRecord) => {
      return a.broker.name.localeCompare(b.broker.name);
    });

    return comparingBrokers;
  }

  public get isDefaultFilter(): boolean {
    
    // @TODO move this to a constant somewhere
    const defaultFilter: BrokerTableFilter = {
      supportedCryptocurrencies: SupportedCryptocurrencies.Any,
      regulationStatus: RegulationStatus.Any,
      headquartersLocation: HeadquartersLocation.Anywhere,
      selectCryptocurrencies: [],
    };
    
    return this.filter.headquartersLocation === defaultFilter.headquartersLocation
      && this.filter.regulationStatus === defaultFilter.regulationStatus
      && this.filter.supportedCryptocurrencies === defaultFilter.supportedCryptocurrencies;
  }

  /**
   * Filtered and sorted brokers
   */
  public get filteredAndSortedBrokers(): BrokerRecord[] {
    const brokers = store.state.broker.brokers.filter((broker: BrokerRecord) => {

      switch (this.filter.headquartersLocation) {
        case HeadquartersLocation.UnitedStates:
          if (broker.fields.headquarters.value.countryCode !== 'US') {
            return false;
          }
          break;
        case HeadquartersLocation.Europe:
          if (broker.fields.headquarters.value.continentCode !== 'EU') {
            return false;
          }
          break;
      }

      switch (this.filter.regulationStatus) {
        case RegulationStatus.Regulated:
          if (!broker.fields[BrokerFieldSlug.Regulators].value.length) {
            return false;
          }
          break;
        case RegulationStatus.Unregulated:
          if (broker.fields[BrokerFieldSlug.Regulators].value.length) {
            return false;
          }
          break;
      }

      switch (this.filter.supportedCryptocurrencies) {
        case SupportedCryptocurrencies.Select:

          for (let i = 0; i < this.selectCryptocurrencyFields.length; i++) {
            // @TODO find "as number" alternative
            if (isNaN(broker.fields[this.selectCryptocurrencyFields[i].slug as BrokerFieldSlug].numericSortValue as number) && broker.fields[this.selectCryptocurrencyFields[i].slug as BrokerFieldSlug].alphaSortValue === '') {
              return false;
            }
          }

          break;
      }

      return true;
    }).filter((broker: BrokerRecord) => {

      // @TODO if user is viewing custom cryptocurrencies, only check the cryptocurrency fields, not Maker and Taker

      // Loop through each custom field (this will exclude common fields like Headquarters, Founded, and Regulated)
      for (let i = 0; i < this.brokerTableCustomFields.length; i++) {
        // If this field is not empty
        if (broker.fields[ this.brokerTableCustomFields[i] ].isEmpty === false) {
          return true;
        }
      }

      return false;
    }).sort((a: BrokerRecord, b: BrokerRecord) => {
      if (this.sortField) {
        if (a.fields[this.sortField].alphaSortValue !== '' || b.fields[this.sortField].alphaSortValue !== '') {
          return a.broker.name.localeCompare(b.broker.name);
        }
        else {
          return ( a.fields[this.sortField].numericSortValue ?? 0 ) - ( b.fields[this.sortField].numericSortValue ?? 0 );
        }
      }
      else {
        return a.broker.name.localeCompare(b.broker.name);
      }
    });

    if (this.sortOrder === SortOrder.Desc) {
      brokers.reverse();
    }

    return brokers;
  }

  @Mutation
  setCategorySlug(categorySlug: BrokerCategorySlug) {
    this.categorySlug = categorySlug;
  }

  @Mutation
  resetFilterToDefault() {
    // @TODO move this to a constant somewhere
    this.filter = {
      supportedCryptocurrencies: SupportedCryptocurrencies.Any,
      regulationStatus: RegulationStatus.Any,
      headquartersLocation: HeadquartersLocation.Anywhere,
      selectCryptocurrencies: [],
    };
  }

  @Mutation
  setFilter(filter: BrokerTableFilter) {
    this.filter = filter;
  }

  @Mutation
  setSupportedCryptocurrencies(supportedCryptocurrencies: SupportedCryptocurrencies) {
    this.filter.supportedCryptocurrencies = supportedCryptocurrencies;
  }

  @Mutation
  setRegulationStatus(regulationStatus: RegulationStatus) {
    this.filter.regulationStatus = regulationStatus;
  }

  @Mutation
  updateSort(sortField: BrokerFieldSlug) {
    const fieldDefaultSortOrder = defaultSortOrder[sortField],
      fieldAlternateSortOrder = fieldDefaultSortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;

    // If this is a different sort field
    if (this.sortField !== sortField) {
      this.sortField = sortField;
      this.sortOrder = fieldDefaultSortOrder;
    }
    // If this is the same sort field
    else {
      // If we are already on the alternate sort order
      if (this.sortOrder === fieldAlternateSortOrder) {
        // Reset to default sort field
        this.sortField = undefined;
        this.sortOrder = SortOrder.Asc;
      }
      else {
        this.sortOrder = fieldAlternateSortOrder;
      }
    }
  }

  @Mutation
  setHeadquartersLocation(headquartersLocation: HeadquartersLocation) {
    this.filter.headquartersLocation = headquartersLocation;
  }

  @Mutation
  addComparingBroker(broker: BrokerRecord) {
    if (!this.comparingBrokers.includes(broker)) {
      this.comparingBrokers.push(broker);
    }
  }

  @Mutation
  removeComparingBroker(broker: BrokerRecord) {
    const index = this.comparingBrokers.indexOf(broker);
    if (index >= 0) {
      this.comparingBrokers.splice(index, 1);
    }
  }

  @Mutation
  resetComparingBrokers() {
    this.comparingBrokers = [];
  }
  
  @Mutation
  toggleComparingBroker(broker: BrokerRecord) {
    const index = this.comparingBrokers.findIndex(b => b.broker.slug === broker.broker.slug);

    if (index >= 0) {
      this.comparingBrokers.splice(index, 1);
    }
    else if (this.comparingBrokers.length < this.maxComparingBrokers) {
      this.comparingBrokers.push(broker);
    }
  }
}