import Vue from 'vue'
import Vuex  from 'vuex'

import broker from './modules/broker'
import paginatedTable from './modules/paginatedTable'
import selectiveBrokerCompare from './modules/selectiveBrokerCompare';
import individualBroker from './modules/individualBroker';
import brokerTable from './modules/brokerTable';
import history from './modules/history';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  modules: {
    broker,
    paginatedTable,
    selectiveBrokerCompare,
    individualBroker,
    brokerTable,
    history
  }
})