






















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {HeaderOverlay} from '@/enums';
import {EventBus} from "@/event-bus";
import Fuse from 'fuse.js'
import Broker from "@/modules/broker";
import {BrokerRecord} from "@/interfaces";
import BrokerLogoLink from "@/components/BrokerLogoLink.vue";
import LocalStore from "@/local-store";

const RecentSearchBrokerIds = 'recentSearchBrokerIds';

@Component({
  components: {BrokerLogoLink}
})
export default class Overlay extends Vue {

  @Prop(Number) readonly headerOverlay: HeaderOverlay | undefined;

  private searchQuery = '';

  private searchResults: BrokerRecord[] = [];

  private recentSearchBrokerIds: number[] = [];

  private $fuse!: Fuse<BrokerRecord>;

  private get HeaderOverlay() {
    return HeaderOverlay;
  }

  /**
   * Array of all recently searched for brokers
   *
   * @private
   */
  private get recentSearchBrokers(): BrokerRecord[] {

    const brokers: BrokerRecord[] = [];

    this.recentSearchBrokerIds.forEach(id => {
      const broker = (this.$store.state.broker as Broker).brokers.find(b => b.broker.id === id);

      if (broker) {
        brokers.push(broker);
      }
    });

    return brokers;
  }

  @Watch('searchQuery')
  onSearchQueryChanged(): void {
    this.$nextTick(() => {
      // Clear search results
      this.searchResults = [];

      // Get Fuse search results
      const results = this.getFuse().search(this.searchQuery).slice(0, 5);

      results.forEach(result => {
        this.searchResults.push(result.item);
      });
    });
  }

  /**
   * Get Fuse instance
   *
   * @private
   */
  private getFuse(): Fuse<BrokerRecord> {
    // If Fuse instance has not yet been created
    if (!this.$fuse) {
      this.$fuse = new Fuse((this.$store.state.broker as Broker).brokers, {
        includeScore: true,
        keys: ['broker.legalName', 'broker.name'],
      });
    }

    return this.$fuse;
  }

  /**
   * Remove a broker from 'Recent Searches'
   *
   * @param broker
   * @private
   */
  private removeRecentSearchBroker(broker: BrokerRecord) {
    const index = this.recentSearchBrokerIds.indexOf(broker.broker.id);

    if (index >= 0) {
      this.recentSearchBrokerIds.splice(index, 1);
    }

    LocalStore.setItem(RecentSearchBrokerIds, this.recentSearchBrokerIds);
  }

  /**
   * After a broker in the search results is selected
   *
   * @param broker
   * @private
   */
  private onSelect(broker: BrokerRecord) {

    const index = this.recentSearchBrokerIds.indexOf(broker.broker.id);

    if (index >= 0) {
      this.recentSearchBrokerIds.splice(index, 1);
    }

    this.recentSearchBrokerIds.unshift(broker.broker.id);
    this.searchQuery = '';

    LocalStore.setItem(RecentSearchBrokerIds, this.recentSearchBrokerIds);

    this.$emit('toggle', HeaderOverlay.Search);
  }

  /**
   * Go to first search result
   *
   * @private
   */
  private onSubmit() {
    if (this.searchResults.length) {
      // Path to first broker in search results
      const path = `/brokers/broker/${this.searchResults[0].broker.slug}`;

      // If use is on a different page
      if (path !== this.$router.currentRoute.path) {
        this.$router.push(path).then(() => {
          this.onSelect(this.searchResults[0]);
        });
      }
      // If user is already viewin
      else {
        this.onSelect(this.searchResults[0]);
      }
    }
  }

  public mounted() {

    this.recentSearchBrokerIds = LocalStore.getItem<number[]>(RecentSearchBrokerIds) ?? [];

    EventBus.$on('focusOnSearch', () => {
      if (this.$refs.search && this.$refs.search instanceof HTMLInputElement) {
        this.$refs.search.focus();
      }
    });

    EventBus.$on('documentKeyDown', (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === 'escape' && document.activeElement === this.$refs.search) {
        this.$emit('toggle', HeaderOverlay.Search);
      }
    });
  }
}
