import {BrokerField, BrokerRecord, Cryptocurrency} from "@/interfaces";
import Api from "@/api/Api";
import {BrokerFieldSlug} from "@/enums";
import {YesNoFieldFormatter} from "@/fieldFormatters/YesNoFieldFormatter";
import {UrlFieldFormatter} from "@/fieldFormatters/UrlFieldFormatter";
import {UrlListFieldFormatter} from "@/fieldFormatters/UrlListFieldFormatter";
import {MobileAppsFieldFormatter} from "@/fieldFormatters/MobileAppsFieldFormatter";
import {SocialWebsitesFieldFormatter} from "@/fieldFormatters/SocialWebsitesFieldFormatter";
import {IntegerFieldFormatter} from "@/fieldFormatters/IntegerFieldFormatter";
import {LocationFieldFormatter} from "@/fieldFormatters/LocationFieldFormatter";
import {PercentRangeFieldFormatter} from "@/fieldFormatters/PercentRangeFieldFormatter";
import {PercentFieldFormatter} from "@/fieldFormatters/PercentFieldFormatter";
import {TransferFieldFormatter} from "@/fieldFormatters/TransferFieldFormatter";

const all: [Promise<BrokerRecord[]>, Promise<BrokerField[]>, Promise<BrokerField[]>, Promise<Cryptocurrency[]>] = [
  Api.instance.getBrokers(),
  Api.instance.getBrokerCategories(),
  Api.instance.getBrokerFields(),
  Api.instance.getCryptocurrencies(),
];

export default Promise.all(all).then(result => {

  const legitBrokers = result[0];

  legitBrokers.forEach(legitBroker => {
    Object.keys(legitBroker.fields).forEach(key => {
      switch (key) {
        case BrokerFieldSlug.Regulated:
          legitBroker.fields[key] = new YesNoFieldFormatter(legitBroker.fields[key]).getData();
          break;
        case BrokerFieldSlug.Url:
          legitBroker.fields[key] = new UrlFieldFormatter(legitBroker.fields[key]).getData();
          break;
        case BrokerFieldSlug.Regulators:
          legitBroker.fields[key] = new UrlListFieldFormatter(legitBroker.fields[key]).getData();
          break;
        case BrokerFieldSlug.MobileApps:
          legitBroker.fields[key] = new MobileAppsFieldFormatter(legitBroker.fields[key]).getData();
          break;
        case BrokerFieldSlug.Social:
          legitBroker.fields[key] = new SocialWebsitesFieldFormatter(legitBroker.fields[key]).getData();
          break;
        case BrokerFieldSlug.Founded:
          legitBroker.fields[key] = new IntegerFieldFormatter(legitBroker.fields[key]).getData();
          break;
        case BrokerFieldSlug.Headquarters:
          legitBroker.fields[key] = new LocationFieldFormatter(legitBroker.fields[key]).getData();
          break;
        case BrokerFieldSlug.DepositAch:
        case BrokerFieldSlug.DepositBtc:
        case BrokerFieldSlug.DepositEth:
        case BrokerFieldSlug.DepositLtc:
        case BrokerFieldSlug.DepositUsdc:
        case BrokerFieldSlug.WithdrawalAch:
        case BrokerFieldSlug.WithdrawalBtc:
        case BrokerFieldSlug.WithdrawalEth:
        case BrokerFieldSlug.WithdrawalLtc:
        case BrokerFieldSlug.WithdrawalUsdc:
          legitBroker.fields[key] = new TransferFieldFormatter(legitBroker.fields[key]).getData();
          break;
        case BrokerFieldSlug.BtcSaveApy:
        case BrokerFieldSlug.EthSaveApy:
        case BrokerFieldSlug.AdaSaveApy:
        case BrokerFieldSlug.DotSaveApy:
        case BrokerFieldSlug.UniSaveApy:
        case BrokerFieldSlug.BchSaveApy:
        case BrokerFieldSlug.LinkSaveApy:
        case BrokerFieldSlug.LtcSaveApy:
        case BrokerFieldSlug.BatSaveApy:
        case BrokerFieldSlug.PaxgSaveApy:
        case BrokerFieldSlug.UsdcSaveApy:
        case BrokerFieldSlug.GusdSaveApy:
        case BrokerFieldSlug.UsdtSaveApy:
          legitBroker.fields[key] = new PercentRangeFieldFormatter(legitBroker.fields[key]).getData();
          break;
        case BrokerFieldSlug.Maker:
        case BrokerFieldSlug.Maker100k:
        case BrokerFieldSlug.Maker1m:
        case BrokerFieldSlug.Taker:
        case BrokerFieldSlug.Taker100k:
        case BrokerFieldSlug.Taker1m:
        case BrokerFieldSlug.BtcUsd:
        case BrokerFieldSlug.EthUsd:
        case BrokerFieldSlug.AdaUsd:
        case BrokerFieldSlug.DotUsd:
        case BrokerFieldSlug.UniUsd:
        case BrokerFieldSlug.BchUsd:
        case BrokerFieldSlug.LinkUsd:
        case BrokerFieldSlug.LtcUsd:
        case BrokerFieldSlug.BatUsd:
        case BrokerFieldSlug.PaxgUsd:
          legitBroker.fields[key] = new PercentFieldFormatter(legitBroker.fields[key]).getData();
          break;
      }
    });
  });

  return {
    brokers: legitBrokers,
    brokerCategories: result[1],
    brokerFields: result[2],
    cryptocurrencies: result[3],
  }
});