import { PageRange, Table as TableInterface } from '@/interfaces'
import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import {SortOrder} from "@/enums";

@Module
export default class PaginatedTable extends VuexModule {
  count = 0

  pages: PageRange[] = [{
    from: 0,
    to: 0,
  }];

  page = 0;

  sortIndex = NaN;

  sortOrder = SortOrder.Asc;

  table: TableInterface = {
    headerRow: {
      cells: [],
    },
    bodyRows: [],
  }

  @Mutation
  increment(delta: number) {
    this.count += delta
  }

  @Mutation
  decrement(delta: number) {
    this.count -= delta
  }

  @Mutation
  setPage(page: number) {
    this.page = page;
  }

  @Mutation
  setTableSortIndex(sortIndex: number) {
    this.sortIndex = sortIndex;
  }

  @Mutation
  setTableSortOrder(sortOrder: SortOrder) {
    this.sortOrder = sortOrder;
  }

  @Mutation
  setPages(pages: PageRange[]) {
    this.pages = pages;
  }

  @Mutation
  setTable(table: TableInterface) {
    this.table = table;
  }
}