import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import BrokerTableView from '../views/BrokerTableView.vue'
import store from "@/store";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'broker-table',
    component: BrokerTableView
  },
  {
    path: '/brokers/compare/:slug*',
    name: 'compare-brokers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "compare" */ '../views/SelectiveBrokerCompareView.vue')
  },
  {
    path: '/brokers/broker/:slug*',
    name: 'individual-broker',
    component: () => import(/* webpackChunkName: "broker" */ '../views/IndividualBrokerView.vue'),
  },
  {
    path: '/404',
    name: 'error-not-found',
    component: () => import(/* webpackChunkName: "broker" */ '../views/NotFoundView.vue'),
  },
  {
    path: '/400',
    name: 'error-bad-request',
    component: () => import(/* webpackChunkName: "broker" */ '../views/BadRequestView.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app')?.scrollIntoView();
  }
});

router.beforeEach((to, from, next) => {
  store.commit('addBackButtonText', 'Back');
  next();
});

window.onpopstate = () => {
  store.commit('onPopState');
}

export default router
