




























import {BrokerRecord} from '@/interfaces';
import {Component, Vue} from 'vue-property-decorator';
import BrokerTable from "@/modules/brokerTable";
import BrokerLogoLink from "@/components/BrokerLogoLink.vue";

const BrokerTableRowProps = Vue.extend({
  props: {
    broker: Object as () => BrokerRecord,
    index: Number,
  },
})
@Component({
  components: {BrokerLogoLink}
})
export default class BrokerTableRow extends BrokerTableRowProps {

  /**
   * @private
   */
  private get isComparingBroker(): boolean {
    return this.$store.state.brokerTable.comparingBrokers.includes(this.broker);
  }

  /**
   * @private
   */
  private get checkboxTitle(): string {
    if (this.isComparingBroker) {
      return 'Remove from compare';// : 'Add to compare'"
    }
    else if (this.isMaxComparingBrokers) {
      return `Max ${(this.$store.state.brokerTable as BrokerTable).maxComparingBrokers} brokers can be compared at once`;
    }

    return 'Add to compare';
  }

  /**
   * @private
   */
  private get checkboxClasses() {
    if (this.isComparingBroker) {
      return ['text-black'];
    }
    else if (this.isMaxComparingBrokers) {
      return ['text-gray-200', 'cursor-not-allowed'];
    }

    return ['text-gray-400 hover:text-black'];
  }

  /**
   * @private
   */
  private get isMaxComparingBrokers(): boolean {
    return this.$store.getters.isMaxComparingBrokers;
  }

  /**
   * @private
   */
  private onCompareClick() {
    this.$store.commit('toggleComparingBroker', this.broker);
  }

}
