











import {Component, Prop, Vue} from 'vue-property-decorator';
import {FieldData} from "@/interfaces";

@Component
export default class BrokerFieldUpdated extends Vue {

  @Prop(Object) readonly brokerFieldData: FieldData | undefined;

  private get displayDate(): string {
    return this.brokerFieldData?.updatedAt ? new Date(this.brokerFieldData.updatedAt).toLocaleDateString() : '';
  }

}
