












import { PageRange } from '@/interfaces';
import { Component, Vue } from 'vue-property-decorator';
import PaginatedTable from "@/modules/paginatedTable";

@Component
export default class TablePagination extends Vue {

  private get pages(): PageRange[] {
    return (this.$store.state.paginatedTable as PaginatedTable).pages;
  }

  private get page(): number {
    return (this.$store.state.paginatedTable as PaginatedTable).page;
  }

  private set page(page: number) {
    this.$store.commit('setPage', page);
  }
}
