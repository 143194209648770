import { UrlListBrokerFieldData } from "@/interfaces";
import { FieldFormatter } from "./FieldFormatter";

export class UrlListFieldFormatter extends FieldFormatter {

  declare protected data: UrlListBrokerFieldData;

  getIsEmpty(): boolean {
    return this.data.value.length === 0;
  }

  getNumericSortValue(): number {
    return 0;
  }
  
  getAlphaSortValue(): string {
    return "";
  }

  getHtml(): string {
    return this.data.value.length ? this.data.value.map((url, index) => {
      const name = url.name || (new URL(url.url)).host;
      const isLast = index >= ( this.data.value.length - 1 );
      return `<a href="${url.url}" target="_blank" rel="noopener" class="">${name}</a>${isLast ? '' : ', '}`;
    }).join('') : '<span class="text-gray-300">-</span>';
  }

  public getData(): UrlListBrokerFieldData {
    return super.getData();
  }
}