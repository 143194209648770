import {Workbox} from "workbox-window";

let wb: Workbox | null = null;

// If service workers are supported and this is a production build
if ("serviceWorker" in navigator && process.env.NODE_ENV === 'production') {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

  wb.addEventListener("controlling", () => {
    window.location.reload();
  });

  wb.register();
}

export default wb;
